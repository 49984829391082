<template>
  <v-app backgroundColor="#673AB7">
    <!-- <v-img :src="require('./assets/tree.png')"></v-img> -->
    <NavBar></NavBar>
    <v-main transition="slide-x-transition" class="bg-body">
      <!-- <v-flex xs12 sm12 md12 class="pa-2"> -->
      <router-view></router-view>
      <!-- </v-flex> -->
    </v-main>
    <v-snackbar
      v-model="$store.getters.snackBar.active" :timeout="3000" :color="$store.getters.snackBar.color"
      :top="true"
      centered>
      {{ $store.getters.snackBar.message }}
      <v-btn text  @click="closeSnackBar()">
        Close
      </v-btn>
    </v-snackbar>
  </v-app>
</template>

<script>
import NavBar from "./components/NavBar";
import { mapState } from "vuex";
import { mapActions} from "vuex";
export default {
  components: {
    NavBar,
  },
  computed: {
    isLoggedIn: function () {
      return this.$store.getters.isLoggedIn;
    },
  },
  methods: {
    ...mapActions(["snackBar"]),
    logout: function () {
      this.$store.dispatch("logout").then(() => {
        this.$router.push("/login");
      });
    },
    closeSnackBar() {
      this.snackBar({enable : false, message : ""});
    }
  },
};
</script>
<style lang="css" scoped>
.bg-body {
  background-color: #f6f9fc;
}
</style>
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./registerServiceWorker";
import vuetify from "./plugins/vuetify";
import axios from "axios";
import VueAxios from "vue-axios";
import moment from "moment";
import Vuelidate from 'vuelidate';
import constPlugin from "./plugins/constPlugin";
import VueApexCharts from 'vue-apexcharts'
import 'aos/dist/aos.css'

Vue.use(VueApexCharts)

Vue.component('apexchart', VueApexCharts)
Vue.config.productionTip = false;
Vue.prototype.moment = moment;
Vue.use(VueAxios, axios);
Vue.use(Vuelidate);
Vue.use(constPlugin);


//Vue.prototype.$companyId = localStorage.getItem('orgId');
(axios.defaults.baseURL = process.env.VUE_APP_BASE_URL + "/avocet/api/v1"),
  axios.interceptors.request.use(
    (config) => {
      const token = localStorage.getItem("token");
      config.headers["Authorization"] = "Bearer " + token;
      return config;
    },

    (error) => {
      return Promise.reject(error);
    }
  );

// ref:https://codepen.io/andersschmidt/post/vuejs-axios-tip-setting-up-multiple-baseurls-using-axios-instances-and-vue-instance-properties
new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");

//https://vuejsexamples.com/sample-admin-template-based-on-vuejs-vuetify/
//https://codepen.io/kematzy/pen/oMgrjE

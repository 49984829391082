<template>
  <v-container>
    <v-row dense data-aos="fade-up" data-aos-duration="800" data-aos-delay="400">
      <v-col>
        <!-- <v-card id="landing-page-home" dark> -->
        <v-card-title>
          Vendors
          <v-spacer></v-spacer>

          <v-dialog v-model="dialog1" persistent max-width="600px">
            <template v-slot:activator="{ on }">
              <v-btn
                v-if="isLoggedIn"
                icon
                color="primary"
                dark
                v-on="on"
                @click="editItem(item)"
              >
                <v-icon>mdi-pencil-outline</v-icon>
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="headline">Vendors</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field
                        v-model="editedItem.text"
                        label="headline"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
                <small>*indicates required field</small>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="dialog1 = false"
                  >Close</v-btn
                >
                <v-btn color="blue darken-1" text @click="dialog1 = false"
                  >Save</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-card-title>

        <v-container fluid>
          <v-row>
            <v-col cols="12">
              <v-row>
                <v-card
                  class="ma-3 pa-6"
                  width="300"
                  color="#9C27B0"
                  elevation="10"
                >
                  <v-card-text>
                    <div class="white--text">register your space</div>
                  </v-card-text>
                  <v-card-actions>
                    <v-btn x-small text to="/register">
                      <div class="white--text">Register</div>
                    </v-btn>
                  </v-card-actions>
                </v-card>

                <v-card
                  dark
                  class="ma-3 pa-6"
                  width="300"
                  v-for="vendor in vendors"
                  :key="vendor.id"
                  color="#0A192F"
                  elevation="10"
                >
                  <!-- {{ vendor }} -->
                  Vendor Id:-{{ vendor.vendorId }}<br />
                  name:-{{ vendor.firstName }}<br />
                  mobile:-{{ vendor.mobile }}<br />
                  email:-{{ vendor.email }}<br />
                  <div v-for="company in vendor.companies" :key="company.id">
                    company name:-{{ company.name }}<br />
                  </div>
                  <!-- total companies:-{{ vendor.companies.length }} -->
                  <div v-for="ecom in vendors.eCommerceDetails" :key="ecom.id">
                    <v-card-text>
                      <div class="title">
                        {{ ecom.vendorId }}
                      </div>
                      <div>{{ ecom.companies }}</div>
                      <!-- <div>E-Mail - {{ vendor.email }}</div> -->
                      <!-- <p class="text-sm-left white--text">{{project.description}}</p> -->
                      <!-- <p class="text white--text">site- {{ ecom.ecomUrl }}</p> -->
                    </v-card-text>

                    <v-card-actions>
                      <v-btn
                        x-small
                        text
                        color="rgb(100, 255, 218)"
                        :href="'http://' + ecom.ecomUrl"
                        target="_blank"
                      >
                        <div>{{ ecom.ecomUrl }}</div>
                      </v-btn>
                    </v-card-actions>
                  </div>
                </v-card>
              </v-row>
            </v-col>
            <v-col cols="12"></v-col>
          </v-row>
        </v-container>

        <!-- <v-col cols="12" sm="6" md="4">
            <v-card v-for="n in 3" :key="n" class="ma-3 pa-6" outlined fluid>Column</v-card>
          </v-col>-->
        <!-- </v-card> -->
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  components: {},
  created() {
    this.initialize();
  },
  computed: {
    isLoggedIn: function () {
      return this.$store.getters.isLoggedIn;
    },
  },

  watch: {},

  methods: {
    initialize() {
      this.$http
        .get("open/org/by/getByRole")
        .then((response) => {
          this.vendors = response.data;
          // alert(this.vendors.username);
          console.log(this.vendors);
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },

  data: () => ({
    dialog1: false,
    vendors: "",
    editedItem: {
      title: "",
      text: "",
    },
    item: {
      href: "Projects",
      color: "#1F7087",
      title: "Projects",
      text: "Projects",
    },
    projects: [
      {
        name: "Edu Pulse",
        domain: "Education",
        responsibility: "coding",
        description:
          "The EDUPULSE- Solution designed and developed for educational institutions of multiple stream, where Fee-Management, Academic, Transport, Hostel with Mess are on Tally ERP Technology with multiple location process environment with web integration using J2EE, servlets",
      },
      {
        name: "CONex",
        domain: "Construction & Developers",
        responsibility: "coding",
        description:
          "Builders And Developers: CONeX - integrated Solution for Builders, Developers and Construction companies with process right from Planning, Budgeting, LeadManagement, SalesManagement, Material Management and contractor. The whole system is integrated with Tally and J2EE and Android",
      },
      {
        name: "Media",
        domain: "Media & Advertisement",
        responsibility: "coding",
        description:
          "Reorganize the Advertising Experience on television & Enable the Consumer/ Subscriber to have more time to analyze his need and the respective AD while not getting interrupted watching the transmitted Program”.The other objective of this system is also to provide strong and seamless integration between the television, the internet and the mobile. This application software  has to be run on the SET-TOP boxes, Web and Smart devices.",
      },
      {
        name: "IHG-GSM",
        domain: "Intercontinental  Hotel Groups",
        responsibility: "coding",
        description:
          "Web Site to update the journal posted by the IHG Management.",
      },
      {
        name: "Quest Mobile",
        domain: "HR",
        responsibility: "coding",
        description:
          "Allow employees to enter their current week time sheet by using this app.  By using this mobile application, user can add/edit their current week time sheet using android device ",
      },
      {
        name: "Credientialing",
        domain: "Health care",
        responsibility: "coding",
        description: "Credentialing the Rep and buyer for healthcare sales",
      },
      {
        name: "Luau",
        domain: "E-Commerce",
        responsibility: "coding",
        description: "E-commerce application for shopping",
      },
      {
        name: "Trav Desk",
        domain: "Travel",
        responsibility: "coding",
        description:
          "Single app to book travel ticket for various boarding and accomodation",
      },

      // {
      //   name: "outlook integration",
      //   domain: "HR Employee Engagement",
      //   responsibility: "coding"
      // }
    ],
  }),
};
</script>

<style scoped>
.title {
  color: rgb(100, 255, 218);
}
#landing-page-home {
  /* background-image: url('../assets/tree.png'); */
  background-color: #172a45;
  background-size: cover;
  position: relative;
}

/* p {
  line-height: 1.5em;
  height: 3em;
  overflow: hidden;
} */

.text {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
  text-align: left;
}
</style>

import Vue from "vue";
import Router from "vue-router";
import store from "./store";
import Home from "./views/Home.vue";
import About from "./views/About.vue";
import Profile from "./views/profile.vue";
import Login from "./components/auth/Login.vue";
import Register from "./components/auth/Register.vue";
import Resource from "./components/resources/Resources.vue";
Vue.use(Router);
const router = new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      name: "home",
      component: Home,
    },

    {
      path: "/error",
      name: "error",
      component: () => import("./views/Error.vue"),
    },
    {
      path: "/company-select",
      name: "company-select",
      component: () => import("./views/CompanySelection.vue"),
    },
    {
      path: "/login",
      name: "login",
      component: Login,
    },
    {
      path: "/register",
      name: "register",
      component: Register,
    },
    // {
    //   path: '/resources',
    //   name: 'resources',
    //   component: Resource,
    //   meta: {
    //     requiresAuth: true,
    //   },
    // },
    {
      path: "/about",
      name: "about",
      component: About,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/profile",
      name: "profile",
      component: Profile,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/dashboard/:id",
      name: "Dashboard",
      component: () => import("./views/Dashboard.vue"),
    },
    {
      path: "/app-users/:id",
      name: "Users",
      component: () => import("./views/AppUsers.vue"),
    },

    // {
    //   path: "/vch-inv-sales-order/:id",
    //   name: "VchSalesInvOrder",
    //   component: () =>
    //     import("./components/vch-sales-types/VchSalesInvOrder.vue"),
    // },

    {
      path: "/acc-groups/:id",
      name: "groups",
      component: () => import("./views/Groups.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/acc-ledgers/:id",
      name: "ledgers",
      component: () => import("./views/Ledgers.vue"),
    },
    {
      path: "/edu-classes/:id",
      name: "classes",
      component: () => import("./views/education/ClassView.vue"),
    },
    {
      path: "/edu-attendence/:id",
      name: "attendence",
      component: () => import("./views/education/Attendence.vue"),
    },
    {
      path: "/student-view/:id",
      name: "student-view",
      component: () => import("./views/education/StudentView.vue"),
    },

    {
      path: "/edu-fee-templates/:id",
      name: "fee-template-view",
      component: () => import("./views/education/FeeTemplate.vue"),
    },

    //=============company creation==================
    {
      path: "/company/",
      name: "Company",
      component: () => import("./views/Company.vue"),
    },

    //=============company settings==================
    {
      path: "/company-settings/:id",
      name: "company-settings",
      component: () => import("./views/CompanySettings.vue"),
    },

    //=========================Sales=====================
    // {
    //   path: '/vch-sales/:id',
    //   name: 'VchSales',
    //   component: () => import('./views/VchSales.vue')
    // },
    // {
    //   path: '/vch-acc-sales/:id',
    //   name: 'VchAccSales',
    //   component: () => import('./views/VchAccSales.vue')
    // },

    {
      path: "/vch-sales-ac/:id",
      name: "VchSalesAc",
      component: () => import("./components/vch-sales-types/VchSalesAc.vue"),
    },
    {
      path: "/vch-sales-ac-new/:id",
      name: "VchSalesAcNew",
      component: () => import("./components/vch-sales-types/VchSalesAcNew.vue"),
    },
    {
      path: "/vch-inv-sales/:id",
      name: "VchInvSales",
      component: () =>
        import("./components/vch-sales-types/VchSalesInvGrid.vue"),
    },
    {
      path: "/vch-inv-inventory-edit/:id",
      name: "VchSalesInvEdit",
      props: true,
      component: () =>
        import("./components/vch-sales-types/VchSalesInvNew.vue"),
    },
    {
      path: "/vch-inv-inventory-new/:id",
      name: "VchSalesInvNew",
      component: () =>
        import("./components/vch-sales-types/VchSalesInvNew.vue"),
    },

    {
      path: "/vch-inv-pos-sales/:id",
      name: "VchPosSales",
      component: () => import("./components/vch-sales-types/PosSales.vue"),
    },
    {
      path: "/vch-inv-sales-receipt/:id",
      name: "VchSalesReceipt",
      props: true,
      component: () =>
        import("./components/vch-sales-types/VchInvSalesReceipt.vue"),
    },

    {
      path: "/vch-sales-print/:id",
      name: "VchSalesPrint",
      component: () => import("./views/VchSalesPrint.vue"),
    },
    {
      path: "/sales-report/:id",
      name: "SalesReport",
      component: () => import("./views/SalesReport.vue"),
    },
    {
      path: "/day-book/:id",
      name: "DayBook",
      component: () => import("./views/DayBook.vue"),
    },

    {
      path: "/report-ledger/:id",
      name: "LedgerReport",
      component: () => import("./views/ReportLedger.vue"),
    },
    //============================purchase======================
    {
      path: "/vch-purchase/:id",
      name: "VchPurchase",
      component: () => import("./views/VchPurchaseGrid.vue"),
    },
    {
      path: "/vch-purchase-edit/:id",
      name: "VchPurchaseEdit",
      props: true,
      component: () => import("./components/vch-purchase-types/VchPurchaseNew.vue"),
    },
    {
      path: "/vch-purchase-add/:id",
      name: "VchPurchaseAdd",
      component: () => import("./components/vch-purchase-types/VchPurchaseNew.vue"),
    },
    //============================purchase-Order======================
    {
      path: "/order/:id",
      name: "Order",
      component: () => import("./views/Order.vue"),
    },
    //============================payment======================

    {
      path: "/vch-inv-payment_old/:id",
      name: "VchInvPayment",
      component: () => import("./views/VchPayment.vue"),
    }, {
      path: "/vch-inv-payment/:id",
      name: "VchInvPayment",
      component: () => import("./components/vch-purchase-types/VchInvPurchasePayment.vue"),
    },

    {
      path: "/vch-acc-payment/:id",
      name: "VchAccPayment",
      component: () => import("./views/accounting/Payment.vue"),
    },

    //==============================inventory=======================
    {
      path: "/stock-groups/:id",
      name: "stockGroups",
      component: () => import("./views/StockGroups.vue"),
    },
    {
      path: "/stock-items/:id",
      name: "stockItems",
      component: () => import("./views/StockItems.vue"),
    },
    {
      path: "/unit-of-measure/:id",
      name: "unitOfMeasure",
      component: () => import("./views/UnitOfMeasure.vue"),
    },
    {
      path: "/stock-category/:id",
      name: "stockCategory",
      component: () => import("./views/StockCategory.vue"),
    },

    {
      path: "/vch-type/:id",
      name: "voucherType",
      component: () => import("./views/VchType.vue"),
    },

    {
      path: "/store-front/:id",
      name: "StoreFront",
      component: () => import("./views/StoreFront.vue"),
      //beforeEnter() { window.open("http://localhost:8081/", '_blank');},
    },

    {
      path: "/product-details/:id",
      name: "productDetails",
      component: () => import("./views/ecommerce/ProductDetails.vue"),
      //beforeEnter() { window.open("http://localhost:8081/", '_blank');},
    },
    {
      path: "/site-settings/:id",
      name: "SiteSettings",
      component: () => import("./views/SiteSettings.vue"),
      //beforeEnter() { window.open("http://localhost:8081/", '_blank');},
    },

    {
      path: "/vendors",
      name: "avocet vendors",
      component: () => import("./views/Vendors.vue"),
    },
    {
      path: "/tree",
      name: "avocet vendors",
      component: () => import("./views/political/FamilyTree.vue"),
    },

    {
      path: "/invites",
      name: "vendor invites",
      component: () => import("./views/Invites.vue"),
    },

    //===============roles===============
    {
      path: "/roles/:id",
      name: "roles",
      component: () => import("./views/Roles.vue"),
    },

    // =========================org registration======================
    {
      path: "/org-registration",
      name: "OrgRegistration",
      component: () => import("./views/avocet/OrgRegistration.vue"),
      //beforeEnter() { window.open("http://localhost:8081/", '_blank');},
    },

    // =========================Avocet Console======================
    {
      path: "/avocet-console",
      name: "AvocetConsole",
      component: () => import("./views/AvocetConsole.vue"),
      //beforeEnter() { window.open("http://localhost:8081/", '_blank');},
    },

    {
      path: "/avocet-super-console",
      name: "AvocetSuperConsole",
      component: () => import("./views/AvocetSuperAdminConsole.vue"),
      //beforeEnter() { window.open("http://localhost:8081/", '_blank');},
    },

    //========================================================

    {
      path: "/whats-app",
      name: "WhatsApp",
      component: () => import("./views/kaleyra-whatsapp/WhatsApp.vue"),
    },

    {
      path: "/vch-inv-sales-order/:id",
      name: "VchSalesInvOrder",
      component: () =>
        import("./components/vch-sales-types/VchSalesInvOrder.vue"),
    },

    {
      path: "/vch-tally-sales",
      name: "VchTallySalesInvOrder",
      component: () => import("./components/vch-tally-sales/VchTallySales.vue"),
    },
    {
      path: "/tally-groups",
      name: "TallyGroups",
      component: () => import("./components/tally-grp/TallyGroup.vue"),
    },
    {
      path: "/tally-ledgers",
      name: "TallyLedgers",
      component: () => import("./components/tally-grp/TallyLedger.vue"),
    },
  ],
});

export default router;
router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.getters.isLoggedIn) {
      next();
      return;
    }
    next("/login");
  } else {
    next();
  }
});

//https://stackoverflow.com/questions/54820409/how-to-use-v-switches-v-model-with-dynamic-array-in-v-datatable

<template>
  <div class="ma-3 pa-10">
    <!-- <Login v-if="!this.isLoggedIn" ></Login> -->
    <v-row justify="center" c>
      <v-avatar size="300" data-aos="zoom-in-up">
        <img class="bouncing-img" :src="require('../assets/pngegg.png')" />
      </v-avatar>

      <!-- <v-list-item-avatar size="400px" item>
          <img :src="require('../assets/avocet3.jpg')" />
        </v-list-item-avatar> -->
    </v-row>

    <v-dialog v-if="(this.company = 'null' && this.isLoggedIn)" v-model="dialog" max-width="500" persistent>
      <v-card color="#9C27B0" dark>
        <!-- <v-avatar size="100">
          <img :src="require('../assets/pngegg.png')" />
        </v-avatar> -->
        <!-- this.capitalized(this.current_user.user.appUserName) -->
        <!-- {{ this.current_user }} -->
        <v-card-title primary-title class="justify-center">
          {{ appUserName }}</v-card-title>
        <!-- <v-divider></v-divider> -->
        <v-list-item three-line>
          <v-list-item-content>
            <v-autocomplete v-if="isLoggedIn" v-model="company" hide-details solo-inverted :items="organizations"
              item-text="name" label="Select Your Company" return-object @change="companyDetails(company)">
              <template slot="no-data">
                <v-card>
                  <v-card-actions class="justify-center">
                    <v-btn v-if="this.isAdmin" color="green" small dark :to="`/company/`">Create Company</v-btn><span
                      v-else>Contact Admin to Assign Company</span>
                  </v-card-actions>
                </v-card>
              </template>
            </v-autocomplete>
            <v-card-actions class="justify-center">
              <v-btn @click="logout" color="green">logout
                <v-icon right dark> mdi-logout </v-icon>
              </v-btn>
            </v-card-actions>
          </v-list-item-content>
        </v-list-item>
      </v-card>
    </v-dialog>

    <v-layout justify-center>
      <v-card-actions data-aos="fade-right" data-aos-duration="600" data-aos-delay="350">
        <h1>Avocet</h1>
        <sup class="green--text">Nidus</sup>
      </v-card-actions>
    </v-layout>

    <p class="text-justify" data-aos="fade-right" data-aos-delay="200" data-aos-duration="400">
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Nidus
      Avocet is an ERP software that is provided as independent modules that can
      be integrated to create a business solution that can be as large as to
      address all your business processes or as minimal as to address a single
      department of your business. This modularity along with its SaaS
      capability and distributed processing nature makes it a flexible and
      cost-effective solution that be adopted by organizations of all size.
    </p>
    <VendorCard></VendorCard>

    <!-- </v-container> -->
    <!-- <v-container fluid fill-height class="pa-10"> </v-container> -->
  </div>
</template>

<script>
import Login from "../components/auth/Login.vue";
import VendorCard from "../components/VendorCard.vue";
import AOS from "aos";

export default {
  components: {
    Login,
    VendorCard,
  },
  mounted() {
    AOS.init();
  },
  created() {
    this.initialize();
    this.getOrganization();
  },
  data: () => ({
    userRole: "",
    isAdmin: "",
    company: "null",
    companyName: "",
    dialog: true,
    companyId: "",
    organizations: [],
    error: "",
    lat: "",
    lon: "",

    current_user: [],
    appUserName: "",
    vendorId: "",
    data: "",
    bg: false,
    menudrawer: false,
    items: [],
    groups: [],
    classes: [],
    sections: [],

    auth_token: "",
  }),

  computed: {
    isLoggedIn: function () {
      return this.$store.getters.isLoggedIn;
    },
  },
  // watch: {
  //   $route: "initialize"
  // },

  methods: {
    companyDetails(company) {
      this.company = company;
      this.companyName = company.name;
      this.companyId = company.id;
      localStorage.setItem("orgId", company.id);
      this.$root.$emit("companyId", company.id);
      this.dialog = false;
      this.$router.push("/dashboard/" + company.id);
    },
    gotToWebSite(site) {
      window.open("www.google.com", "_blank");
    },
    myFunction() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(this.showPosition);
      } else {
        this.error = "Geolocation is not supported.";
      }
    },
    showPosition(position) {
      this.lat = position.coords.latitude;
      this.lon = position.coords.longitude;
    },
    getOrganization() {
      var userId = localStorage.getItem("id");
      this.$http
        .get("/getbyid", {
          params: {
            id: userId,
          },
        })
        .then((response) => {
          this.current_user = response.data;
          this.userRole = this.current_user.user.appUserRole;
          this.appUserName = this.current_user.user.appUserName;
          this.vendorId = this.current_user.user.vendorId;
          if (this.userRole == "ADMIN") {
            this.isAdmin = true;
          } else {
            this.isAdmin = false;
          }

          this.organizations = this.current_user.companies;
        })
        .catch((err) => {
          this.status_code = err.response ? err.response.data.status : {};
          if (this.status_code == 401) {
            this.logout();
          }
        });
      // this.$http
      //   .get("/open/org/getall")
      //   .then(response => {
      //     this.organizations = response.data;
      //   })
      //   .catch(err => {
      //     console.log(err);
      //   });
    },
    initialize() {
      this.companyId = localStorage.getItem("orgId");
      if (this.companyId == 0 && this.isLoggedIn) {
        this.dialog = true;
      }
    },
    logout: function () {
      this.$store.dispatch("logout").then(() => {
        this.appUserName = "";
        (this.current_user = []),
          (this.groups = []),
          this.$router.push("/").catch(() => {});
      });
    },
  },
};
</script>

<style>
.bouncing-img {
    -webkit-animation: bounceFountain;
    animation: bounceFountain;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-direction: normal;
    animation-delay: .4s;
}
.head {
  font-size: 48px;
  text-align: center;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 3px;
}

.sub_head {
  font-size: 28px;
  text-align: center;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 3px;
}

h1 {
  font-size: 40px;
}

h2 {
  font-size: 30px;
}

#landing-page-home {
  /* background-image: url('../assets/tree.png'); */

  height: 100vh;

  background-size: cover;
  position: relative;
}
</style>

//http://www.mooneyimages.com/collections //https://vue-babylonjs.com/#/texture
//https://natain-portfolio.netlify.app/contact
